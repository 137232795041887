<template>
  <div class="uiCellV1" v-debounce="['click', onCellClick, 500]">
    <div
      v-debounce="['click', onLeftClick, 500]"
      class="uiCellV1_left"
      :class="leftClass"
    >
      <img :src="leftIcon" v-if="leftIconShow" :class="leftIconClass" />
      <span v-if="leftTextShow" :class="leftTextClass">{{ leftText }}</span>
    </div>
    <div slot="content" class="uiCellV1_content">
      <slot></slot>
    </div>
    <div
      v-debounce="['click', onRightClick, 500]"
      class="uiCellV1_right"
      :class="rightClass"
    >
      <img :src="rightIcon" v-if="rightIconShow" :class="rightIconClass" />
      <span v-if="rightTextShow" :class="rightTextClass">{{ rightText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "uiCellV1",
  data(){
    return {

    }
  },
  props: {
    leftClass: {
      type: Array,
      default() {
        return ["uiCellV1-left"];
      },
    },
    leftIcon: {
      type: String,
      default: require("@/static/image/icons/hotGoods.png"),
    },
    leftIconShow: {
      type: Boolean,
      default: true,
    },
    leftIconClass: {
      type: Array,
      default() {
        return [];
      },
    },
    leftText: {
      type: String,
      default: "查看更多",
    },
    leftTextShow: {
      type: Boolean,
      default: true,
    },
    leftTextClass: {
      type: Array,
      default() {
        return [];
      },
    },

    rightClass: {
      type: Array,
      default() {
        return ["uiCellV1-right"];
      },
    },
    rightIcon: {
      type: String,
      default: require("@/static/image/icons/arrowRight.png"),
    },
    rightIconShow: {
      type: Boolean,
      default: true,
    },
    rightIconClass: {
      type: Array,
      default() {
        return [];
      },
    },
    rightText: {
      type: String,
      default: "查看全部",
    },
    rightTextShow: {
      type: Boolean,
      default: true,
    },
    rightTextClass: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    onLeftClick() {
      this.$emit("onLeft");
    },
    onRightClick() {
      this.$emit("onRight");
    },
    onCellClick() {
      this.$emit("onCell");
    },
  },
};
</script>

<style lang="scss" scoped>
@include b(uiCellV1) {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include e(content) {
    flex-grow: 1;
  }
  @include e(left) {
    display: flex;
    flex-shrink: 0;
  }
  @include e(right) {
    display: flex;
    flex-shrink: 0;
    flex-direction: row-reverse;
  }
}
</style>
